import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
// import { ReactSortable } from 'react-sortablejs' // to delete

import { useReactiveVar } from '@apollo/client'
import {
  advancedProductTotalPriceVar,
  advancedProductVarsResetedVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricVar,
  selectedSofaCombinationsVar,
  sofaScaleVar,
} from '../../../../store/reactiveVars'
import ConfigurationBlock from './Components/ConfigurationBlock'
import { getCurrentPriceCategory } from '../../../../utilityFunctions/priceUtils'
import SectionTitle from './SectionTitle'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },

      sectionTitle: {
        marginTop: '50px',
        marginLeft: '30px',
        marginRight: '30px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down('lg')]: {
          marginTop: '10px',
        },
      },

      mainBox: {
        padding: '10px',
      },
    }),
  { name: 'MuiChairConfigurationPreview' },
)

export default function AdvancedProductConfigurationPreview({
  advancedProduct,
  discount,
  ...props
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])
  const reviewYourConfigurationText = t('review-your-configuration-text')
  const pleaseSelectConfigurationText = t('please-select-a-configuration-text')


  // ------ STATE -----
  // const [clickeSofaShapes, setClickeSofaShapes] = useState([])

  // const selectedSofaCombinations = useReactiveVar(selectedSofaCombinationsVar)
  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  const selectedAddtionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

  const priceCategories =
    advancedProduct?.advanced_product?.advanced_product_price_fabric_category
  const fabricGroup = selectedAdvancedProductFabric.fabricGroupObject

  //----- CALCULATE TOTAL PRICE -------

  let totalPrice = 0
  if (fabricGroup && priceCategories?.length > 0) {
    // Find the base price, by Fabric material...
    for (const pCategory of priceCategories) {
      const fabricGroupsInThisCategory =
        pCategory.fabrice_price_category.fabric_groups
      const index = fabricGroupsInThisCategory.findIndex((object) => {
        return object.id === fabricGroup.id
      })
      if (index > -1) {
        // We found the right pCategory;
        totalPrice = totalPrice + pCategory.price
        break
      }
    }
    //==== Add extra price for EachAdditionalComponent
    for (const aComponent of selectedAddtionalComponents) {
      //====== PRICE CALCULATTIONS ======
      // 1) Component wil either have extra_price
      // 2) Or a PriceFabricCategory - price depending on the selected Fabric.
      let price
      if (
        aComponent.use_fabric_prices_for_components &&
        aComponent.price_fabric_category &&
        aComponent.price_fabric_category.length &&
        selectedAdvancedProductFabric?.fabricGroupObject
          ?.form_price_fabric_category
      ) {
        price = getCurrentPriceCategory(
          selectedAdvancedProductFabric,
          aComponent.price_fabric_category,
        )
      } else {
        price = aComponent.extra_price
      }

      totalPrice = totalPrice + price
    }
  } else {
    // === SET THE BASE PRICE
    const basePrice = advancedProduct?.advanced_product?.base_price ?? 0
    totalPrice = totalPrice + basePrice
    // IF there is no FABRIC GROUP E.G.: Tables, Lights...
    //==== Add extra price for EachAdditionalComponent
    for (const aComponent of selectedAddtionalComponents) {
      let componentExtraPrice
      componentExtraPrice = aComponent.extra_price
      totalPrice = totalPrice + componentExtraPrice
    }
  }
  //====== END PRICE CALCULATTIONS  (above) ======

  const advancedProductVarsReseted = useReactiveVar(
    advancedProductVarsResetedVar,
  )
  useEffect(() => {
    advancedProductTotalPriceVar(totalPrice)
  }, [totalPrice, advancedProductVarsReseted])
  //----- ^^^ (above) CALCULATE TOTAL PRICE -------

  // Data
  // 1) We have a product container (For discounts)
  // 2) We have a selected fabric
  // 3) we have sofa combinations

  return (
    <Grid container justifyContent="space-evenly" spacing={1}>
      <SectionTitle title={reviewYourConfigurationText} />

      <Grid container justifyContent="space-evenly" spacing={0} marginTop={3}>
        <Grid item md={12} style={{ width: '100%' }}>
          {selectedAdvancedProductFabric.fabricGroupObject ? (
            <ConfigurationBlock
              discount={discount}
              productName={advancedProduct.name}
              selectedFabric={selectedAdvancedProductFabric}
              selectedAddtionalComponents={selectedAddtionalComponents}
              advancedProduct={advancedProduct}
              fabricDisabled={false}
            />
          ) : (
            <>
              {selectedAddtionalComponents ? (
                <ConfigurationBlock
                  discount={discount}
                  productName={advancedProduct.name}
                  selectedFabric={selectedAdvancedProductFabric}
                  selectedAddtionalComponents={selectedAddtionalComponents}
                  advancedProduct={advancedProduct}
                  fabricDisabled={true}
                />
              ) : (
                <p>{pleaseSelectConfigurationText}</p>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
